(function () {

    'use strict';

    angular
        .module('app.spm.help')
        .service('helpService', helpService);

    function helpService($state, $rootScope, $mdDialog) {
        //this function will take the path to i18n en.json file that will be used to populate the help dialog bindings
        //second parameter is the jsonPathIndex of the specific help object to show. 
        function showHelpDialog(jsonPathIndex, ev, useCalculate) {
            $mdDialog.show({
                locals: { jsonPathIndex: jsonPathIndex, useCalculate: useCalculate },
                controller: 'HelpController as vm',
                multiple: true,
                clickOutsideToClose: true,
                templateUrl: "assets/templates/help/help-dialog.html",
                targetEvent: ev,
            }).then(function () { }, function onCancel() {
                // telemetryService.insightsDashboardTelemetry("close", "help");

                var currentSession = JSON.parse(sessionStorage.getItem('segmentData'));

                delete currentSession.card_name;
                delete currentSession.sort_order;
                delete currentSession.card_page;

                sessionStorage.setItem('segmentData', JSON.stringify(currentSession));
            });
        }
        return {
            showHelpDialog: showHelpDialog,
        }
    }
})();
