(function ()
{
    'use strict';

    angular
        .module('app.pages.maintenance')
        .controller('MaintenanceController', MaintenanceController);

    /** @ngInject */
    function MaintenanceController()
    {
        // Data

        // Methods

        //////////
    }
})();