﻿(function () {
    "use strict";

    angular
        .module("app.spm.signal-events")
        .controller("signalEventsController", signalEventsController);

    function signalEventsController($state, $scope, searchBarService) {
        var vm = this;

        vm.searchBarService = searchBarService;
        //set options for the search bar
        vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
            //header information
            header: {
                show: true,
                text: "Signal Events",
            },
            //search bar options
            helpJsonPropertyPath: "SIGNAL_EVENTS." + "GENERAL_HELP",
            showHelp: true,   
            showSearchBar: true,
            searchType: 'Signals',
            showCurrentFilterDates: true,
            showExcelRaw: true,
            showSignalNotificationTabs: true,                      
            timeFrameConfig: {
                enableWithoutFiltering: false,
                defaultDateTemplate: "TD",
                defaultTodTemplate: "FD",
                dateTemplateMinimumDays: 0,
                timeOptionForCustomTemplate: "StartToEnd",
                showWeekdayFilter: false,
                maxDayRange: 31,
            },
            //right-side more options menu
            moreOptionsMenu: {
                show: false,
                showBinConfig: false,
                skipStepsPerBin: true,
            }
        });
        vm.isFiltering = searchBarService.isFiltering();

        vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
            if (!vm)
                return; 

            switch (changeType) {
                case "state":
                    vm.isFiltering = searchBarService.isFiltering();
                    break;
            }
        });

        $scope.$on("$destroy", function () {
            vm = null;
        })
    }
}());
