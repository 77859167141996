import moment from "moment";

(function () {
    "use strict";

    angular
        .module('app.core')
        .controller('insightsRightMenuController', insightsRightMenuController);

    function insightsRightMenuController($scope, $stateParams, $rootScope, $translate, userService, $mdDialog, $timeout, $mdSidenav, newWindowService, searchBarService, signalService, insightsService, userSettingsService) {
        var vm = this;
        vm.searchBarService = searchBarService;
        vm.insightsMenuPinned = false;
        vm.pinInsightsMenu = pinInsightsMenu;
        vm.toggleOptionsSidenav = toggleOptionsSidenav;
        vm.updateBookmarks = updateBookmarks;
        vm.getNewTimeFrame = getNewTimeFrame;
        vm.investigateInsight = investigateInsight;

        vm.collapseInsights = collapseInsights;
        vm.clearInsights = clearInsights;
        vm.getSearchOptions = getSearchOptions;

        vm.formatAMPM = formatAMPM;

        vm.getApproachDescription = getApproachDescription;
        vm.getDetectorType = getDetectorType;
        vm.getDetectorOrder = getDetectorOrder;

        vm.flipInsight = flipInsight;

        vm.options = {};
        vm.briefInsights = $scope.insights;
        vm.openInsightCategory = openInsightCategory;
        vm.placeInsightItemsInTable = placeInsightItemsInTable;
        vm.getInsightData = getInsightData;
        vm.getSearchBarData = getSearchBarData;
        vm.insightsMenuPinned = false;
        vm.backToInsightCategories = backToInsightCategories;

        vm.acknowledgeInsight = acknowledgeInsight;
        vm.ignoreInsight = ignoreInsight;
        vm.saveAckItem = saveAckItem;
        vm.updateAckItem = updateAckItem;
        vm.removeAckItem = removeAckItem;
        vm.setIgnore = setIgnore;


        vm.bookmarkedInsights = [];

        vm.monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        function updateBookmarks() {
            $rootScope.$broadcast('bookmarks-open');
        }

        function findOccurrencesForDate(date) {
            var occurrencesForDate = [];
            var dailyOccurences = vm.currentInsight.occurrences.filter(occ => new Date(occ.start).getFullYear() == date.getFullYear()
                && new Date(occ.start).getMonth() == date.getMonth() && new Date(occ.start).getDate() == date.getDate());
            vm.firstOcc =
            {
                start: moment(dailyOccurences[0].start).format('hh:mm A'),
                end: moment(dailyOccurences[0].end).format('hh:mm A')
            };
            if (dailyOccurences.length > 1) {
                vm.lastOcc =
                {
                    start: moment(dailyOccurences[dailyOccurences.length - 1].start).format('hh:mm A'),
                    end: moment(dailyOccurences[dailyOccurences.length - 1].end).format('hh:mm A')
                };
            } else {
                vm.lastOcc = ""
            }
            return occurrencesForDate;
        }

        vm.flatModel = undefined;
        vm.dateOpts = {
            dateFormat: 'Y-m-d',
            placeholder: 'Change date..', // Default: 'Select Date..'
            defaultDate: [],
            mode: "multiple",
            locale: {
                firstDayOfWeek: 1
            },
            onReady: function () {
                // document.querySelector('.flatpickr-calendar').innerHTML += '<span class="tooltiptext" id="date-tooltiptext">Tooltip text</span>';

            },
            onMonthChange: function (dObj, dStr, fp, dayElem) {
                setupMonthNotification(fp);
            },
            inline: true,
            onDayCreate: function (dObj, dStr, fp, dayElem) {
                // if (dayElem.classList.contains('selected')) dayElem.innerHTML += '<span md-z-index="999999" class="tooltiptext" id="date-tooltiptext">Tooltip text</span>';

                dayElem.addEventListener('mouseover', function (e) {
                    if (dayElem.classList.contains('selected')) {
                        var tooltip = document.getElementById('occurrences-tooltip');
                        var rect = e.target.getBoundingClientRect();
                        var y = e.target.offsetTop - 80;
                        var moveLeft = e.target.offsetLeft;
                        if (moveLeft > 170) {
                            moveLeft = 170;
                        } else if (moveLeft < 34) {
                            moveLeft = 34;
                        }
                        tooltip.style.top = y + "px";
                        tooltip.style.left = moveLeft + "px";
                        tooltip.style.visibility = 'visible';
                        tooltip.style.opacity = 1;
                        vm.dateToShow = moment(dayElem.dateObj).format('MMM DD, YYYY');
                        vm.occurrencesToShow = findOccurrencesForDate(dayElem.dateObj);
                        $scope.$apply();
                    }
                });

                dayElem.addEventListener('mouseout', function (e) {
                    if (dayElem.classList.contains('selected')) {
                        var tooltip = document.getElementById('occurrences-tooltip');
                        tooltip.style.visibility = 'hidden';
                        tooltip.style.opacity = 0;
                    }
                });

                dayElem.addEventListener('mousedown', function (e) {
                    e.stopPropagation();
                });

                if (dayElem.classList.contains("selected")) {
                    dayElem.style.backgroundColor = vm.currentInsight.color;
                    dayElem.style.border = 'none';
                }

            }
        };

        vm.datePostSetup = function (fpItem) {
            setupMonthNotification(fpItem.fpItem);
        }

        function setupMonthNotification(fp) {
            var futureMonthToCompare = fp.currentMonth + 1;
            var previousMonthToCompare = fp.currentMonth - 1;

            var hasFutureOcc = false;
            var hasPrevOcc = false;
            vm.dateOpts.defaultDate.forEach(function (date) {
                if (futureMonthToCompare == (new Date(date)).getMonth() && fp.currentYear == new Date(date).getFullYear()) {
                    hasFutureOcc = true;
                }
                if (previousMonthToCompare == (new Date(date)).getMonth() && fp.currentYear == new Date(date).getFullYear()) {
                    hasPrevOcc = true;
                }
                if (futureMonthToCompare == 12 && fp.currentYear + 1 == new Date(date).getFullYear()) {
                    hasFutureOcc = true;
                }
                if (previousMonthToCompare == 1 && fp.currentYear - 1 == new Date(date).getFullYear()) {
                    hasPrevOcc = true;
                }
            });
            if (document.getElementById('prev-month-div')) {
                document.getElementById('prev-month-div').remove();
            }
            if (document.getElementById('next-month-div')) {
                document.getElementById('next-month-div').remove();
            }
            if (hasFutureOcc) {
                document.querySelector('.flatpickr-next-month').innerHTML += `<div id="next-month-div" class="${vm.currentInsight.border}" 
                style="border-style: solid; border-width: 1px;  border-color: ${vm.currentInsight.color}; width: 15px; height: 15px;
                position: absolute; top: 3px; right: 5px; z-index: 1;
                border-radius: 50%">!</div>`;
            }
            if (hasPrevOcc) {
                document.querySelector('.flatpickr-prev-month').innerHTML += `<div id="prev-month-div"
                style="border-style: solid; border-width: 1px; border-color: ${vm.currentInsight.color}; width: 15px; height: 15px;
                position: absolute; top: 3px; left: 5px; z-index: 1;
                border-radius: 50%">!</div>`;
            }
        }

        function pinInsightsMenu(folded) {
            if (!document.getElementById('insights-lightbulb-div')) return;
            if (folded) {
                angular.element('#content-container').css('margin-right', document.getElementById('insights-sidepanel').offsetWidth + 'px');
                document.getElementById('content-container').classList.add('pinned-sidemenu');
                document.getElementById('insights-lightbulb-div').style.display = 'none';
                angular.element('md-backdrop').css('display', 'none');
                vm.insightsMenuPinned = true;

            } else {
                angular.element('#content-container').css('margin-right', '0px');
                document.getElementById('content-container').classList.remove('pinned-sidemenu');
                if (document.getElementById('insights-lightbulb-div')) document.getElementById('insights-lightbulb-div').style.display = 'block';
                angular.element('md-backdrop').css('display', 'block');
                vm.insightsMenuPinned = false;
            }

            $timeout(function () {
                window.dispatchEvent(new Event('resize'));
            });
        }

        // =================== insights menu ===============================

        signalService.getAllSignalsWithApproaches().then(function () {
            vm.getSearchBarData();
            if (vm.queryData.signalID != '' && vm.queryData.start != '' && vm.queryData.end)
                vm.getInsightData();
        });

        $rootScope.$on('insights-sidemenu-open', function () {
            if (vm.insightsMenuPinned) {
                vm.pinInsightsMenu(false)
            }
        });

        function backToInsightCategories() {
            document.getElementById('insights-sidemenu-list').classList.remove('md-active');
            // telemetryService.insightsDashboardTelemetry("back", "insight_selection", {
            //     insight_category: vm.currentlySelectedInsightCategory.id,
            //     intersection_id: vm.signal && vm.signal.signalID != "" ? vm.signal.signalID : undefined,
            //     intersection_name: vm.signal && vm.signal.signalID != "" ? vm.signal.signalName : undefined,
            //     intersection_state: vm.signalState ? vm.signalState.state : undefined
            // });
        }

        function getSignalDescription(customId,primaryName,secondaryName){

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '')
            {
                res += " - " + secondaryName;
            }
      
            return res;
        }

        function getNewTimeFrame(start, end) {
            var options = vm.searchBarService.getSearchOptions();
            options.timeOptions.timeFrame = "custom";
            var startDate = new Date(start).toLocaleString();
            var endtDate = new Date(end).toLocaleString();
            options.timeOptions.currentFilter.startDateAndTime = new Date(startDate);
            options.timeOptions.currentFilter.endDateAndTime = new Date(endtDate);
            if(options.timeOptions.currentFilter.endDateAndTime.getHours() == 23){
                options.timeOptions.currentFilter.endDateAndTime.setMinutes(59);
            options.timeOptions.currentFilter.endDateAndTime.setSeconds(59);
            }
            options.timeOptions.currentFilter.selectedTodTemplate = 'CT';
            return options.timeOptions;
        }

        function investigateInsight(insight) {
            // var newTimeOptions = {};
            // newTimeOptions.currentFilter = {};
            // newTimeOptions.currentFilter.startDateAndTime = new Date(vm.queryData.start);
            // newTimeOptions.currentFilter.endDateAndTime = new Date(vm.queryData.end);

            var newTimeOptions = vm.getNewTimeFrame(insight.occurrences[0].start, insight.occurrences[insight.occurrences.length-1].end);
            if (insight.name.includes("Data")) {
                var startDate = new Date(insight.occurrences[0].start);
                var endDate = new Date(new Date(insight.occurrences[insight.occurrences.length-1].end).getTime() - 1);
                var newTimeOptions = vm.getNewTimeFrame(startDate, endDate);
            }

            vm.signal.description = getSignalDescription(vm.signal.customID, vm.signal.primaryName, vm.signal.secondaryName);

            // telemetryService.insightsDashboardTelemetry("click", "investigate", {
            //     insight_category: vm.currentlySelectedInsightCategory.id,
            //     intersection_state: (vm.signalState && vm.signalState.state) ? vm.signalState.state : undefined,
            //     intersection_id: vm.signal && vm.signal.signalID != "" ? vm.signal.signalID : undefined,
            //     intersection_name: vm.signal && vm.signal.signalID != "" ? vm.signal.signalName : undefined,
            //     insight_label: insight.name,
            //     insight_id: insight.id,
            //     approach_id: insight.approachID,
            //     approach: insight.approachID ? getApproachDescription(insight.signalID, insight.approachID) : undefined,
            //     detector_channel: insight.detectorChannel ? insight.detectorChannel : undefined,
            //     detector: (insight.approachID && insight.detectorChannel) ? getDetectorType(insight.signalID, insight.approachID, insight.detectorChannel) : undefined,
            //     number_of_occurrences: insight.occurrences.length,
            //     acknowledged: insight.isAcknowledged
            // });

            var investigatingFromInsights = {
                navigatingFromInsights: true,
                categoryToInvestigate: insight.categoryId
            }
            sessionStorage.setItem("__investigating_from_insights", JSON.stringify(investigatingFromInsights));

            newWindowService.openNewTabTimeAndSignal(
                insight.investigateAction,
                newTimeOptions,
                vm.signal,
                { 'focusApproachId': (insight.approachID ? insight.approachID : vm.signal.approachID) }
            );
            var investigatingFromInsights = {
                navigatingFromInsights: false,
                categoryToInvestigate: null
            }
            sessionStorage.removeItem('__investigating_from_insights');
        }


        vm.bookmarkInsight = function (insight) {
            var hasDetectorChannel = insight.detectorChannel ? insight.detectorChannel : "";
            var hasPhaseNumber = insight.phaseNumber ? insight.phaseNumber : "";
            var insightID = insight.signalID + " " + insight.insightTypeID + " " + hasDetectorChannel + hasPhaseNumber;
            vm.bookmarkedInsights.push(insightID);

            insight.bookmarked = true;
            vm.bookmarksLoading = true;
            userSettingsService.getUserSettings()
                .then(function (userSettings) {
                    userSettings.insightSettings.bookmarkedInsights.push(insightID);
                    userSettingsService.setUserSettings(userSettings);
                    vm.bookmarksLoading = false;
                })
        }

        vm.removeBookmark = function (insight) {
            var hasDetectorChannel = insight.detectorChannel ? insight.detectorChannel : "";
            var hasPhaseNumber = insight.phaseNumber ? insight.phaseNumber : "";
            var insightID = insight.signalID + " " + insight.insightTypeID + " " + hasDetectorChannel + hasPhaseNumber;

            vm.bookmarksLoading = true;
            userSettingsService.getUserSettings()
                .then(function (userSettings) {
                    userSettings.insightSettings.bookmarkedInsights = userSettings.insightSettings.bookmarkedInsights.filter(ins => ins != insightID);
                    userSettingsService.setUserSettings(userSettings);
                    vm.updateBookmarks();
                    vm.bookmarksLoading = false;
                })
            insight.bookmarked = false;
        }

        function clearInsights() {
            if (!vm.maintenanceInsights || !vm.otherInsights || !vm.insights) return;
            vm.maintenanceInsights.insights = [];
            vm.maintenanceInsights.count = 0;
            vm.otherInsights.insights = [];
            vm.otherInsights.count = 0;
            vm.insights.clear();
            insightsService.setInsights(vm.insights);
        }

        function collapseInsights() {
            vm.briefInsights.forEach(function (insight) {
                insight.expanded = false;
            });
        }

        function getSearchOptions() {
            vm.options = vm.searchBarService.getSearchOptions();
        };

        // ===================================================================================


        // ================== FORMAT DATE / TIME ================================= //
        function formatAMPM(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }

        vm.formatDate = formatDate;
        function formatDate(d) {
            const date = new Date(d);

            var dateStr =
                vm.monthNamesShort[date.getMonth()] + "-" +
                ("00" + date.getDate()).slice(-2) + "-" +
                date.getFullYear() + " " +
                formatAMPM(date);
            return dateStr;
        }
        // =========================================================================== //

        vm.flipOccurrences = function (insight) {
            vm.showFullOccurences = !vm.showFullOccurences;

            // vm.showFullOccurences ? telemetryService.insightsDashboardTelemetry("open", "occurrence_period", {
            //     insight_category: vm.currentlySelectedInsightCategory.id,
            //     intersection_state: (vm.signalState && vm.signalState.state) ? vm.signalState.state : undefined,
            //     intersection_id: vm.signal && vm.signal.signalID != "" ? vm.signal.signalID : undefined,
            //     intersection_name: vm.signal && vm.signal.signalID != "" ? vm.signal.signalName : undefined,
            //     insight_label: insight.name,
            //     insight_id: insight.id,
            //     approach_id: insight.approachID,
            //     approach: getApproachDescription(insight.signalID, insight.approachID),
            //     detector_channel: insight.detectorChannel,
            //     detector: (insight.approachID && insight.detectorChannel) ? getDetectorType(insight.signalID, insight.approachID, insight.detectorChannel) : undefined,
            //     number_of_occurrences: insight.occurrences.length,
            //     acknowledged: insight.isAcknowledged
            // })
            //     : telemetryService.insightsDashboardTelemetry("close", "occurrence_period", {
            //         insight_category: vm.currentlySelectedInsightCategory.id,
            //         intersection_state: (vm.signalState && vm.signalState.state) ? vm.signalState.state : undefined,
            //         intersection_id: vm.signal && vm.signal.signalID != "" ? vm.signal.signalID : undefined,
            //         intersection_name: vm.signal && vm.signal.signalID != "" ? vm.signal.signalName : undefined,
            //         insight_label: insight.name,
            //         insight_id: insight.id,
            //         approach_id: insight.approachID,
            //         approach: getApproachDescription(insight.signalID, insight.approachID),
            //         detector_channel: insight.detectorChannel,
            //         detector: (insight.approachID && insight.detectorChannel) ? getDetectorType(insight.signalID, insight.approachID, insight.detectorChannel) : undefined,
            //         number_of_occurrences: insight.occurrences.length,
            //         acknowledged: insight.isAcknowledged
            //     });
        }


        // open up the specific insight
        function flipInsight(insight) {
            vm.insightsToShow.forEach(function (ins) {
                if (ins.id == insight.id) {
                    ins.flipped = !ins.flipped;
                    var extraSegmentProps = {
                        insight_category: insight.categoryId,
                        insight_label: insight.name,
                        insight_id: insight.id,
                        intersection_id: vm.signal.signalID,
                        intersection_name: vm.signal.signalName,
                        intersection_state: vm.signalState.state,
                        approach_id: insight.approachID,
                        approach: insight.approachID ? getApproachDescription(insight.signalID, insight.approachID) : undefined,
                        detector_channel: insight.detectorChannel ? insight.detectorChannel : undefined,
                        detector: (insight.approachID && insight.detectorChannel) ? getDetectorType(insight.signalID, insight.approachID, insight.detectorChannel) : undefined,
                        occurrences: insight.occurrences.length,
                        acknowledged: insight.isAcknowledged
                    }

                    var currentSession = JSON.parse(sessionStorage.getItem('segmentData'));
                    delete currentSession.system_state_overview_selection;
                    sessionStorage.setItem('segmentData', JSON.stringify(currentSession));

                    // ins.flipped ? telemetryService.insightsDashboardTelemetry("open", "insight_selection", extraSegmentProps)
                    //     : telemetryService.insightsDashboardTelemetry("close", "insight_selection", extraSegmentProps);
                } else {
                    ins.flipped = false;
                }
            });
            vm.bookmarksArray && vm.bookmarksArray.forEach(function (ins) {
                if (ins.id == insight.id) {
                    ins.flipped = !ins.flipped;
                } else {
                    ins.flipped = false;
                }
            });
            var allOccurrencesDates = [];
            vm.allOccurrences = insight.occurrences;
            insight.occurrences.forEach(function (occurrence) {
                allOccurrencesDates.push(moment(occurrence.start).format('yyyy-MM-DD'))
            });
            var noDuplicateOccDates = allOccurrencesDates.reduce(function (a, b) { if (a.indexOf(b) < 0) a.push(b); return a; }, []);
            vm.dateOpts.defaultDate = noDuplicateOccDates.reverse();
            vm.currentInsight = insight;
        }

        function getApproachDescription(signalID, approachID) {
            var approaches = signalService.getSignalByIDFromDict(signalID).approaches;
            var description = 'Not Configured';
            approaches.forEach(function (approach) {
                if (approachID == approach.approachID) description = approach.description;
            });
            return description;
        }

        function getDetectorType(signalID, approachID, detectorChannel) {
            // get approach by id
            var approaches = signalService.getSignalByIDFromDict(signalID).approaches;
            var detectorDescription = 'Not Configured Det ' + detectorChannel;

            var selectedApproach = approaches.filter(appr => appr.approachID == approachID)[0];

            if (selectedApproach) {
                selectedApproach.detectors.forEach(function (detector) {
                    if (detector.detChannel == detectorChannel) detectorDescription = detector.description + ' Det ' + detectorChannel
                })
            }

            return detectorDescription
            // get detector type by detector channel from approaches
        }

        function getDetectorOrder(signalID, approachID, detectorChannel) {
            var approaches = signalService.getSignalByIDFromDict(signalID).approaches;
            var orderByType = '';
            var selectedApproach = approaches.filter(appr => appr.approachID == approachID)[0];

            if (selectedApproach) {
                selectedApproach.detectors.forEach(function (detector) {

                    if (detector.detChannel == detectorChannel) {

                        switch (detector.description) {
                            case 'Stop Bar Presence':
                                orderByType = 0;
                                break;
                            case 'Advanced Count':
                                orderByType = 1;
                                break;
                            case "Lane-by-lane Count":
                                orderByType = 2;
                                break;
                            default:
                                orderByType = 3;
                        }
                    }
                })
            }

            return orderByType
        }

        userSettingsService.subscribe($scope, function onChange(ev, changeType) {
            if (changeType == "UserSettingsSaved") {
                vm.getSearchBarData();
                if (vm.queryData.signalID != '' && vm.queryData.start != '' && vm.queryData.end)
                    vm.getInsightData();
                else vm.clearInsights();
            }
        });

        vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
            if (!vm)
                return;
            if (!document.getElementById('insights-sidemenu-list')) return;

            let currentEndDate = vm.queryData.end;

            if (changeType == "configuration") {


                // close insight side menu if open
                if (document.getElementById('insight-menu') && !document.getElementById('insight-menu').hasClass('md-closed')) {
                    if (vm.insightsMenuPinned && document.getElementById('main').classList.contains('pinned-sidemenu')) {
                        vm.toggleOptionsSidenav()
                    }
                    vm.pinInsightsMenu(false)
                    vm.insightsMenuPinned = false;
                }
                vm.getSearchOptions();
                if (currentEndDate) {
                    let endDateFromOptions = vm.options.timeOptions.currentFilter.endDateAndTime.toLocaleString();
                    if (currentEndDate != endDateFromOptions) {
                        vm.getSearchBarData();
                        if (vm.queryData.signalID != '' && vm.queryData.start != '' && vm.queryData.end && vm.isFiltering)
                            vm.getInsightData();
                    } else if (vm.briefInsights && vm.briefInsights.length > 0) {
                        vm.collapseInsights()
                    }
                }
                vm.isFiltering = vm.searchBarService.isFiltering();
            }
            if (changeType == "signal" || changeType == "time" || changeType == "corridor") {
                vm.isFiltering = vm.searchBarService.isFiltering();
                vm.getSearchOptions();
                if (changeType == "signal" || changeType == "time") {
                    vm.getSearchBarData();
                    if (vm.queryData.signalID != '' && vm.queryData.start != '' && vm.queryData.end && vm.isFiltering)
                        vm.getInsightData();
                    else vm.clearInsights();
                }
                if (vm.queryData.signalID == "") {
                    vm.pinInsightsMenu(false);
                    vm.insightsMenuPinned = false;
                }
            }

            if (changeType == "state") {
                vm.isFiltering = searchBarService.isFiltering();
            }

            if (document.getElementById('insights-sidemenu-list').classList.contains('md-active')) {
                document.getElementById('insights-sidemenu-list').classList.remove('md-active')
            }
        });


        // We remove the investigate buttons from the side menu
        // Code is left in case we change our minds :D (will comment it out before release);
        function openInsightCategory(insight) {
            newWindowService.openNewTabTimeAndSignal(
                `app.spm.dashboards.insights.${insight.name.replace(/\s+/g, '-').toLowerCase()}`,
                undefined,
                vm.signal
            );

        }

        // expanding insight category
        vm.toggleView = function (ary, data, index) {
            if (data.insights.length == 0) return;
            vm.placeInsightItemsInTable(data)
            for (var i = 0; i < ary.length; i++) {
                if (i != index) { ary[i].expanded = false; }
                else { data.expanded = !data.expanded; }
            }
            document.getElementById('insights-sidemenu-list').classList.add('md-active');
            vm.currentlySelectedInsightCategory = data;

            var currentSession = JSON.parse(sessionStorage.getItem('segmentData'));
            delete currentSession.system_state_overview_selection;

            sessionStorage.setItem('segmentData', JSON.stringify(currentSession));

            // telemetryService.insightsDashboardTelemetry("open", "insight_category_selection", {
            //     insight_category: vm.currentlySelectedInsightCategory.id,
            //     intersection_state: (data.state && data.state.signalID != "") ? data.state.state : undefined,
            //     intersection_id: vm.signal && vm.signal.signalID != "" ? vm.signal.signalID : undefined,
            //     intersection_name: vm.signal && vm.signal.signalID != "" ? vm.signal.signalName : undefined,
            // });
        }

        // toggle side-menu
        function toggleOptionsSidenav() {
            $mdSidenav('insights-sidepanel').toggle();
        }

        // ==============================================

        // preparing insights to be shown in the side menu.
        // Needed because different insight levels require different fields
        function placeInsightItemsInTable(group) {
            if (!group) return;

            vm.insightsToShow = [];
            //filter out the ignored insights here since we do not show them
            //also, ignored insights concern object will be null 
            var validInsights = group.insights.filter(x => !x.isIgnored);
            validInsights.sort((x, y) => y.concern.level - x.concern.level);
            for (let i = 0; i < validInsights.length; i++) {
                var currentInsight = validInsights[i];


                // vm.loading = false;
                // vm.bookmarksLoading = false;
                // $interval.cancel(bookmarksInterval);
                // setting different classes for insights based on priority
                // some of this code will be commented out for this release
                var icon, iconColor, color;
                switch (currentInsight.concern.level) {
                    case 1:
                        icon = 'mdi-alert-circle';
                        iconColor = 'high-concern-solid';
                        color = '#D0021B';
                        break;
                    case 2:
                        icon = 'mdi-alert-circle-outline';
                        iconColor = 'high-concern-outline';
                        color = '#F5A623';
                        break;
                    case 3:
                        icon = 'mdi-alert';
                        iconColor = 'low-concern-solid';
                        color = '#6A7B8B';
                        break;
                    case 4:
                        icon = 'mdi-alert-outline';
                        iconColor = 'low-concern-outline';
                        color = '#4CAF50';
                        break;
                }
                if (currentInsight.isAcknowledged) {
                    icon = 'mdi-checkbox-marked-circle'
                    // color = 'rgb(76, 175, 80)';
                }

                // Prettify the date for showing all occurences
                currentInsight.occurrences.forEach(function (occurrence) {
                    occurrence.start = moment(occurrence.start).format("MMMM DD, YYYY HH:mm");
                    occurrence.end = moment(occurrence.end).format("MMMM DD, YYYY HH:mm");
                });

                var enhanceInsightObj = {
                    id: i,
                    restrictedControlls: (group.id == 4 && !userService.getUserRoles().some(x => x == 'ADMIN')),
                    categoryName: group.name,
                    categoryId: group.id,
                    icon: icon,
                    iconColor: iconColor,
                    color: color,
                    orderWeight: currentInsight.currentAcknowledgment.acknowledgedByEmail ? -1 : currentInsight.priority.priority,
                    decisionReason: currentInsight.occurrences[0].decisionReason,
                    startDate: currentInsight.occurrences[0].start,
                    endDate: currentInsight.occurrences[0].end,
                }

                switch (currentInsight.level) {
                    case 'Signal':
                        enhanceInsightObj.columnOne = vm.formatDate(currentInsight.occurrences[0].start);
                        enhanceInsightObj.columnOneDescription = "First Occurence: ";
                        enhanceInsightObj.columnTwo = vm.formatDate(currentInsight.occurrences[currentInsight.occurrences.length - 1].end);
                        enhanceInsightObj.columnTwoDescription = "Last Occurence: ";
                        enhanceInsightObj.columnThree = currentInsight.occurrences.length + " " + (currentInsight.occurrences.length == 1 ? 'occurrence' : 'occurrences');
                        break;
                    case 'Detector':
                    case 'PedDetector':
                        enhanceInsightObj.columnOne = getApproachDescription(currentInsight.signalID, currentInsight.approachID);
                        enhanceInsightObj.columnOneDescription = "Approach: ";
                        enhanceInsightObj.columnTwo = getDetectorType(currentInsight.signalID, currentInsight.approachID, currentInsight.detectorChannel);
                        enhanceInsightObj.columnTwoDescription = "Detector Information: ";
                        enhanceInsightObj.columnThree = currentInsight.occurrences.length + " " + (currentInsight.occurrences.length == 1 ? 'occurrence' : 'occurrences');
                        enhanceInsightObj.columnFour = moment(currentInsight.occurrences[currentInsight.occurrences.length - 1].start).format("MMMM DD, YYYY HH:mm");
                        enhanceInsightObj.dateForSort = Date.parse(currentInsight.occurrences[0].start);
                        enhanceInsightObj.orderByType = getDetectorOrder(currentInsight.signalID, currentInsight.approachID, currentInsight.detectorChannel);
                        break;
                    case 'Phase':
                    case 'PedPhase':
                        enhanceInsightObj.columnOne = getApproachDescription(currentInsight.signalID, currentInsight.approachID);
                        enhanceInsightObj.columnOneDescription = "Approach: ";
                        enhanceInsightObj.columnThree = currentInsight.occurrences.length + " " + (currentInsight.occurrences.length == 1 ? 'occurrence' : 'occurrences');
                        enhanceInsightObj.columnFour = moment(currentInsight.occurrences[currentInsight.occurrences.length - 1].start).format("MMMM DD, YYYY HH:mm");
                        enhanceInsightObj.dateForSort = Date.parse(currentInsight.occurrences[0].start);
                        enhanceInsightObj.orderByType = getDetectorOrder(currentInsight.signalID, currentInsight.approachID, currentInsight.detectorChannel);
                        break;
                }

                var insightToShow = Object.assign({}, currentInsight, enhanceInsightObj);

                var hasDetectorChannel = insightToShow.detectorChannel ? insightToShow.detectorChannel : "";
                var hasPhaseNumber = insightToShow.phaseNumber ? insightToShow.phaseNumber : "";
                var insightID = insightToShow.signalID + " " + insightToShow.insightTypeID + " " + hasDetectorChannel + hasPhaseNumber;

                // vm.bookmarkIDsArray.forEach(function (bookmark) {
                //     if (bookmark == insightID) {
                //         vm.bookmarksArray.push(insightToShow);
                //         insightToShow.bookmarked = true;
                //     }
                // });

                // if (!group.forBookmarks) {
                vm.insightsToShow.push(insightToShow);
                // }
            }
        }

        vm.queryData = {
            orderBy: '',
            pageSize: 10,
            pageIndex: 1,
            filter: '',
            start: '',
            end: '',
            signalID: '',
        };

        // =====  code needed for the signal trends to show hatch patterns ====== // 
        vm.maintenanceInsights = {
            display: false,
            count: 0,
            insights: [],
            getMaintenanceInfoText: function () {
                if (this.count == 0) {
                    return "No maintenance issues found for this signal";
                }
                return "This is likely caused by malfunctioning detectors";
            }
        };

        vm.otherInsights = {
            display: false,
            count: 0,
            insights: [],
        };

        function getSearchBarData() {
            var opts = searchBarService.getSearchOptions();
            if (opts.signal && opts.signal.signalID != "") {
                vm.queryData.signalID = opts.signal.signalID;
                signalService.getSignalById(vm.queryData.signalID)
                    .then(function (signal) {
                        vm.signal = signal;
                        vm.signalDescription = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
                    })
            }
            vm.queryData.start = opts.timeOptions.currentFilter.startDateAndTime.toLocaleString();
            vm.queryData.end = opts.timeOptions.currentFilter.endDateAndTime.toLocaleString();
        }

        function getInsightData() {
            $scope.loading = true;
            vm.loading = true;
            vm.maintenanceInsights.insights = [];
            vm.maintenanceInsights.count = 0;
            vm.otherInsights.insights = [];
            vm.otherInsights.count = 0;
            vm.insights = new Map();
            insightsService.getSystemInsightsBySignal(vm.queryData).then(function (data) {
                //do stuff
                var maintenanceCat = data.insightCategories.filter(x => x.insightCategoryID == 1)[0];
                vm.maintenanceInsights.display = maintenanceCat.display;
                var otherCat = data.insightCategories.filter(x => x.insightCategoryID == 3)[0];

                vm.availableCategories = data.insightCategories.filter(x => x.display);
                vm.categoriesToShow = [];
                userSettingsService.getUserSettings()
                    .then(function (userSettings) {
                        if (!vm.signal.enabled) {
                            vm.loading = false;
                            $scope.$apply();
                            return;
                        }
                        vm.categoriesToShow = userSettings.insightSettings.enabledDashboardInsightCategories;

                        vm.briefInsights = [];
                        vm.summedInsightsCount = 0;
                        var investigateInsightsParams;

                        vm.signalState = data.signalState;
                        vm.signalStateUrl = "assets/icons/signal-states/" + data.signalState.state.replace(/\s+/g, '-').toLowerCase() + ".png";
                        // check if there's item in session storage before grabbing the parameters
                        if (sessionStorage && sessionStorage.__investigating_from_insights) {
                            investigateInsightsParams = JSON.parse(sessionStorage.__investigating_from_insights);
                        }



                        data.insightCategories.sort((x, y) => (x.displayOrder > y.displayOrder) ? 1 : -1);
                        data.insightCategories.forEach(function (category) {
                            vm.categoriesToShow.forEach(function (availableCategory) {
                                if (category.insightCategoryID == availableCategory.insightCategoryID) {
                                    vm.currentCategory = availableCategory;
                                    var allInsightsInCategory = [];
                                    category.insightGroups.forEach(function (group) {
                                        group.insightTypes.forEach(function (insType) {
                                            if (insType.display && vm.currentCategory.insightTypes.find(x => x.insightTypeID == insType.insightTypeID)) {
                                                vm.summedInsightsCount += insType.insights.length;
                                                insType.insights.forEach(function (insight) {
                                                    insight.name = insType.name;
                                                    insight.investigateAction = insType.investigateAction;
                                                    insight.acknowledgedActions = insType.acknowledgedActions;
                                                    insight.severity = insType.insightSeverity;
                                                });
                                                allInsightsInCategory.push(...insType.insights);
                                            }
                                        });
                                    });
                                    var isExpanded = false;
                                    var insightsInCat = allInsightsInCategory.filter(x => x.concern);

                                    var allGood = false;

                                    if (insightsInCat.filter(x => !x.isIgnored).length == 0) {
                                        allGood = true;
                                    }

                                    var badgeColor = "#4CAF50";

                                    $translate('INSIGHTS_SIDEPANEL.SIGNAL_STATE_DESCRIPTIONS.' + data.signalState.state)
                                        .then(function (translatedValue) {
                                            if (translatedValue) {
                                                $scope.signalStateTitle = translatedValue;
                                            }
                                        });

                                    if (category.highestConcernSummary) {
                                        switch (category.highestConcernSummary.concern.level) {
                                            case 1:
                                                badgeColor = "#D0021B";
                                                break;
                                            case 2:
                                                badgeColor = "#F5A623";
                                                break;
                                            case 3:
                                                badgeColor = "#6A7B8B";
                                                break;
                                        }
                                    }
                                    // if there's params from session storage,
                                    // that means that the user is investigating insight
                                    // so expand the category he's coming from
                                    var highestConcernCount = category.highestConcernSummary ? category.highestConcernSummary.count : 0;
                                    var singleInsightCategory = {
                                        displayOrder: category.displayOrder,
                                        iconName: 'mdi-' + category.iconName,
                                        allGood: allGood,
                                        badgeColor: badgeColor,
                                        name: category.name,
                                        id: category.insightCategoryID,
                                        count: highestConcernCount,
                                        insights: allInsightsInCategory.filter(x => !x.isIgnored),
                                        expanded: isExpanded,
                                        state: vm.signalState
                                    }

                                    if (insightsInCat.filter(x => !x.isIgnored).length > 0 && investigateInsightsParams && investigateInsightsParams.navigatingFromInsights && category.insightCategoryID == investigateInsightsParams.categoryToInvestigate) {
                                        vm.placeInsightItemsInTable(singleInsightCategory);
                                        document.getElementById('insights-sidemenu-list').classList.add('md-active');
                                        vm.currentlySelectedInsightCategory = singleInsightCategory;
                                        var investigatingFromInsights = {
                                            navigatingFromInsights: false,
                                            categoryToInvestigate: null
                                        }
                                        sessionStorage.setItem("__investigating_from_insights", JSON.stringify(investigatingFromInsights));
                                    }

                                    vm.briefInsights.push(singleInsightCategory);
                                }
                            });
                        });

                        vm.briefInsights.sort((x, y) => (x.displayOrder > y.displayOrder) ? 1 : -1);
                        // cleanup session storage after using the params for navigation
                        // should probably remove the item instead of setting properties to false and null
                        // if (investigateInsightsParams && !investigateInsightsParams.navigatingFromInsights) {
                        //     var investigatingFromInsights = {
                        //         navigatingFromInsights: false,
                        //         categoryToInvestigate: null
                        //     }
                        //     sessionStorage.setItem("__investigating_from_insights", JSON.stringify(investigatingFromInsights));
                        // }

                        vm.loading = false;
                        $scope.loading = false;
                        vm.otherInsights.display = otherCat.display;
                        vm.insights.set("Maintence", maintenanceCat);
                        vm.insights.set("Other", otherCat);
                        insightsService.setInsights(vm.insights, true);
                    });
            })
                .catch(function (error) {
                    $scope.loading = false;
                    insightsService.setInsights(vm.insights, false);
                });
        }

        // =================== insight bookmarks =============================
        $rootScope.$on('bookmarks-open', function () {
            var bookmarkedInsightsGroup = {
                forBookmarks: true,
                insights: []
            };

            vm.bookmarksLoading = true;
            userSettingsService.getUserSettings()
                .then(function (userSettings) {
                    var bookmarkedInsights = userSettings.insightSettings.bookmarkedInsights;

                    vm.availableCategories.forEach(function (category) {
                        category.insightGroups.forEach(function (group) {
                            group.insightTypes.forEach(function (insType) {
                                insType.insights.forEach(function (insight) {
                                    // vm.bookmarkedInsights.push(insight)
                                    bookmarkedInsights.forEach(function (ins) {
                                        var insightStringID = insight.signalID + " " + insight.insightTypeID + " " + (insight.detectorChannel ? insight.detectorChannel : "") + (insight.phaseNumber ? insight.phaseNumber : "");
                                        if (ins == insightStringID) {
                                            bookmarkedInsightsGroup.insights.push(insight);
                                            insight.flipped = false;
                                        }
                                    });
                                });
                            });
                        });
                    });
                    vm.placeInsightItemsInTable(bookmarkedInsightsGroup);
                });

            if (vm.insightsMenuPinned) {
                vm.pinInsightsMenu(false)
            }
        })


        // ===================================================================

        // ==================== Acknowledge Insight ==========================

        $rootScope.$on('insight-acknowledged', function () {
            if (vm.queryData.signalID != '' && vm.queryData.start != '' && vm.queryData.end)
                vm.getInsightData();
        });
        $rootScope.$on('insight-unackowledged', function () {
            if (vm.queryData.signalID != '' && vm.queryData.start != '' && vm.queryData.end)
                vm.getInsightData();
        });

        function getEmail() {
            return sessionStorage.getItem('userEmail');
        }

        function saveAckItem(acknowledged) {
            insightsService.setAcknowledgeItem(acknowledged)
                .then(function () {
                    acknowledged.isAcknowledged = true;
                    acknowledged.newOccurrenceAfterExpiration = false;
                    acknowledged.previousAcknowledgement.acknowledgedComment = "";
                    acknowledged.previousAcknowledgement.selectedAckReasons = [];
                    acknowledged.previousAcknowledgement.acknowledgedByEmail = "";
                    acknowledged.color = '#4CAF50';
                    // vm.getInsightData();
                    // vm.placeInsightItemsInTable(vm.briefInsights.filter(x => x.name == acknowledged.categoryName)[0]);
                }).catch(err => console.error(err))
        }
        function updateAckItem(acknowledged) {
            insightsService.updateAcknowledgeItem(acknowledged)
                .then(function () {
                    acknowledged.isAcknowledged = true;
                    acknowledged.newOccurrenceAfterExpiration = false;
                    acknowledged.previousAcknowledgement.acknowledgedComment = "";
                    acknowledged.previousAcknowledgement.selectedAckReasons = [];
                    acknowledged.previousAcknowledgement.acknowledgedByEmail = "";
                    // vm.placeInsightItemsInTable(vm.currentlySelectedInsightCategory);
                });
        }


        function removeAckItem(acknowledged) {
            insightsService.removeAcknowledgeItem(acknowledged)
                .then(function () {
                    acknowledged.isAcknowledged = false;
                    acknowledged.newOccurrenceAfterExpiration = false;
                    acknowledged.currentAcknowledgment.acknowledgedComment = "";
                    acknowledged.currentAcknowledgment.selectedAckReasons = [];
                    acknowledged.currentAcknowledgment.acknowledgedByEmail = "";
                    acknowledged.currentAcknowledgment.acknowledgedTimestamp = "";
                    acknowledged.previousAcknowledgement.acknowledgedComment = "";
                    acknowledged.previousAcknowledgement.selectedAckReasons = [];
                    acknowledged.previousAcknowledgement.acknowledgedByEmail = "";
                    // vm.placeInsightItemsInTable(vm.currentlySelectedInsightCategory);
                });
        }

        function acknowledgeInsight(insight) {
            var ackItem = insight
            signalService.getSignalById(ackItem.signalID)
                .then(function (signal) {
                    ackItem.signalDescription = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
                    if (ackItem.approachID) {
                        ackItem.approachDescription = vm.getApproachDescription(signal.signalID, ackItem.approachID);
                    }

                    // if (!ackItem.isAcknowledged) {
                    //     telemetryService.insightsDashboardTelemetry("click", "acknowledge", {
                    //         insight_category: ackItem.categoryId,
                    //         intersection_id: ackItem.signalID,
                    //         intersection_name: ackItem.signalDescription,
                    //         intersection_state: vm.signalState.state,
                    //         insight_label: ackItem.name,
                    //         insight_id: ackItem.id,
                    //         approach_id: ackItem.approachID,
                    //         approach: ackItem.approachDescription,
                    //         detector_channel: ackItem.detectorChannel,
                    //         detector: (ackItem.approachID && ackItem.detectorChannel) ? getDetectorType(ackItem.signalID, ackItem.approachID, ackItem.detectorChannel) : undefined,
                    //         number_of_occurrences: ackItem.occurrences.length,
                    //         acknowledged: ackItem.isAcknowledged
                    //     });
                    // }
                });
            var title = this.title;
            $mdDialog.show({
                clickOutsideToClose: true,
                multiple: true,
                templateUrl: 'app/main/spm/dashboards/insights/view/acknowledge-event.panel.html',
                controller: 'AcknowledgeController as vm',
                parent: angular.element(document.body),
                locals: {
                    typeOfAcknowledges: insight.acknowledgedActions,
                    selectedItem: ackItem,
                    insightName: insight.name,
                    on_acknowledge: function (data_from_dialog_controller) {
                        ackItem.isAcknowledged = data_from_dialog_controller.isAcknowledged;
                        ackItem.newOccurrenceAfterExpiration = data_from_dialog_controller.newOccurrenceAfterExpiration;
                        ackItem.currentAcknowledgment.acknowledgedComment = data_from_dialog_controller.comment;
                        ackItem.currentAcknowledgment.selectedAckReasons = data_from_dialog_controller.selectedAckReasons;
                        ackItem.currentAcknowledgment.acknowledgedTimestamp = data_from_dialog_controller.acknowledgedTimestamp;
                        ackItem.currentAcknowledgment.acknowledgedByEmail = getEmail();
                        ackItem.startTime = vm.queryData.start;
                        ackItem.endTime = vm.queryData.end;
                        vm.saveAckItem(ackItem);

                        var ackResponse = undefined;

                        if (ackItem && ackItem.currentAcknowledgment &&
                            ackItem.currentAcknowledgment.selectedAckReasons && ackItem.currentAcknowledgment.selectedAckReasons.length > 0
                            && ackItem.acknowledgedActions && ackItem.acknowledgedActions.length > 0) {
                            ackResponse = {
                                confirmed_in_atms: ackItem.currentAcknowledgment.selectedAckReasons.some(x => x == 1),
                                investigated_in_atms: ackItem.currentAcknowledgment.selectedAckReasons.some(x => x == 2),
                                technician_dispatched: ackItem.currentAcknowledgment.selectedAckReasons.some(x => x == 3),
                                technician_repaired: ackItem.currentAcknowledgment.selectedAckReasons.some(x => x == 4),
                            }
                        }

                        // telemetryService.insightsDashboardTelemetry("acknowledge", "acknowledge", {
                        //     insight_category: ackItem.categoryId,
                        //     intersection_id: ackItem.signalID,
                        //     intersection_name: ackItem.signalDescription,
                        //     intersection_state: vm.signalState.state,
                        //     insight_label: ackItem.name,
                        //     insight_id: ackItem.id,
                        //     approach_id: ackItem.approachID,
                        //     approach: ackItem.approachDescription,
                        //     detector_channel: ackItem.detectorChannel,
                        //     detector: (ackItem.approachID && ackItem.detectorChannel) ? getDetectorType(ackItem.signalID, ackItem.approachID, ackItem.detectorChannel) : undefined,
                        //     number_of_occurrences: ackItem.occurrences.length,
                        //     acknowledged: ackItem.isAcknowledged,
                        //     acknowledge_parameters: [{
                        //         acknowledge_expiration_dt: new Date(ackItem.currentAcknowledgment.acknowledgedExpirationTimestamp),
                        //         comment: ackItem.currentAcknowledgment.acknowledgedComment,
                        //         other: ackResponse
                        //     }]
                        // });
                    },
                    on_update: function (data_from_dialog_controller) {
                        //update acknowledged info
                        ackItem.isAcknowledged = data_from_dialog_controller.isAcknowledged;
                        ackItem.currentAcknowledgment.acknowledgedComment = data_from_dialog_controller.comment;
                        ackItem.currentAcknowledgment.selectedAckReasons = data_from_dialog_controller.selectedAckReasons;
                        ackItem.startTime = vm.queryData.start;
                        ackItem.endTime = vm.queryData.end;
                        vm.updateAckItem(ackItem);
                    },
                    on_unacknowledge: function () {
                        //remove acknowledged info                          
                        vm.removeAckItem(ackItem);
                    }
                }
            }).then(function () { }, function onCancel() {
                // telemetryService.insightsDashboardTelemetry("cancel", "acknowledge", {
                //     insight_category: ackItem.categoryId,
                //     intersection_id: ackItem.signalID,
                //     intersection_name: ackItem.signalDescription,
                //     intersection_state: vm.signalState.state,
                //     insight_label: ackItem.name,
                //     insight_id: ackItem.id,
                //     approach_id: ackItem.approachID,
                //     approach: ackItem.approachDescription,
                //     detector_channel: ackItem.detectorChannel,
                //     detector: (ackItem.approachID && ackItem.detectorChannel) ? getDetectorType(ackItem.signalID, ackItem.approachID, ackItem.detectorChannel) : undefined,
                //     number_of_occurrences: ackItem.occurrences.length,
                //     acknowledged: ackItem.isAcknowledged
                // });
            });
        }

        function ignoreInsight(ignoreItem) {
            var d = new Date();
            signalService.getSignalById(vm.queryData.signalID)
                .then(function (signal) {
                    var signalDescription = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
                    var approachDescription = "";
                    if (ignoreItem.approachID) {
                        approachDescription = getApproachDescription(signal.signalID, ignoreItem.approachID);
                    }
                    var confirmText = "Are you sure you want to ignore " + ignoreItem.name + " for " +
                        approachDescription + " " +
                        signalDescription + "?";

                    var confirm = $mdDialog.confirm()
                        .title("Confirm")
                        .textContent(confirmText)
                        .ok('Yes')
                        .cancel('No');

                    $mdDialog.show(confirm)
                        .then(function () {
                            ignoreItem.ignoredTimestamp = d.toLocaleTimeString() + ' ' + d.toDateString();
                            ignoreItem.isIgnored = true;
                            ignoreItem.ignoredByEmail = getEmail();
                            vm.setIgnore(ignoreItem);
                        }, function () { }
                        );
                });

        }

        function setIgnore(ignoredItem) {
            insightsService.setIgnoreItem(ignoredItem).then(() => {
                vm.currentlySelectedInsightCategory.insights.forEach(function (insight) {
                    var d = new Date();

                    var hasDetectorChannel = insight.detectorChannel ? insight.detectorChannel : "";
                    var hasPhaseNumber = insight.phaseNumber ? insight.phaseNumber : "";
                    var insightID = insight.signalID + " " + insight.insightTypeID + " " + hasDetectorChannel + hasPhaseNumber; ignoredItem
                    var insightStringID = ignoredItem.signalID + " " + ignoredItem.insightTypeID + " " + (ignoredItem.detectorChannel ? ignoredItem.detectorChannel : "") + (ignoredItem.phaseNumber ? ignoredItem.phaseNumber : "");
                    if (insightID == insightStringID) {
                        insight.ignoredTimestamp = d.toLocaleTimeString() + ' ' + d.toDateString();
                        insight.isIgnored = true;
                        insight.ignoredByEmail = getEmail();
                    }
                    vm.getInsightData();
                });
                vm.placeInsightItemsInTable(vm.currentlySelectedInsightCategory)
            });
        }

        // ===================================================================
    }
})();